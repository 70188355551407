export const isPdf = (fileName: string) =>
  fileName.slice(-3).toLowerCase() === 'pdf'

export const validateInput = (e: Event): string | undefined => {
  const input = e.target as HTMLInputElement
  return input.validity.valid
    ? undefined
    : input.validity.patternMismatch
    ? input.title
    : input.validationMessage
}

export const toFormData = (object: Record<string, any>) => {
  const data = new FormData()
  for (const key in object) {
    if (!object[key]) {
      continue
    }
    data.append(key, object[key])
  }
  return data
}

export const resizeTextArea = (e: Event) => {
  const target = e.target as HTMLTextAreaElement
  target.style.height = 'auto'
  target.style.height = target.scrollHeight + 'px'
}
